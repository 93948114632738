<template>
  <div>
    <Navbar/>

    <div class="section bg-slate text-gray-100">
      <div class="w-container pt-8">
        <div class="text-lg md:text-2xl text-white overflow-x-auto">Mining Profile</div>

        <div class="text-md md:text-xl text-white overflow-x-auto">{{ publicAddress }}</div>

        <div class="whitespace-sm"></div>

        <div class="text-lg font-bold">Options</div>
        <!--        {{ethAddress}}-->
        <div>
          Community Fee: {{ communityFeeFormatted() }} %
          <button @click="OpenModal" v-show="ethAddress.toLowerCase() === publicAddress">
            <i class="far fa-edit"></i>
          </button>
        </div>
        <div>
          Currency Priority: {{ priorityName || "BNBTC" }}
          <button @click="OpenPriorityModal" v-show="ethAddress.toLowerCase() === publicAddress">
            <i class="far fa-edit"></i>
          </button>
        </div>
        <!--        <div v-if="priorityName === 'ETI'">
                  Token-switch threshold: {{ switchPercentage || "Pool Default" }}
                  <button @click="OpenSwitchPercentageModal" v-show="ethAddress.toLowerCase() === publicAddress">
                    <i class="far fa-edit"></i>
                  </button>
                </div>-->

        <!--        <div hidden>
                  0xbtc Pool Selected: {{ remotePoolStatusForMiner.displayName || "none" }}
                  <button
                      @click="OpenRemotePoolModal"
                  >
                    <i class="far fa-edit"></i>
                  </button>
                </div>-->
        <br/>
        <br/>

        <div v-if="minerData">
          <div class="profile-table">
            <table class="table w-full">
              <tr style="vertical-align: top;" class="responsive-table">
                <td style="width:7.5%;">
                  <img src="../assets/images/logo1.png" class="bnbtc-logo" alt/>
                </td>
                <td style="width:42.5%;">
                  <div class="text-lg font-bold">BNBTC</div>

                  <div>Hashrate Average: {{ hashrateToMH(minerData.bscAvgHashrate) }} GH/s</div>
                  <div>Tokens Earned: {{ bscTokenBalanceFormatted() }}</div>
                  <div>Tokens Paid: {{ bscTokensAwardedFormatted() }}</div>
                  <div>Tokens Donated: {{ bscTokensDonatedFormatted() }}</div>
                  <div>Tokens Balance: {{ bscTotalTokenBalanceFormatted() }}</div>
                </td>


                  <td style="width:50%;">
                  <div class="request not-visible">
                    Request BNBTC Payout:
                    <button @click="OpenPayoutModal">
                      <i class="far fa-edit"></i>
                    </button>
                  </div>
                  </td>
              </tr>
            </table>

            <div v-if="bscWorkerData.length > 0">
              <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
              <div style="text-align: center;">Total Number of Workers: {{ bscWorkerData.length }}</div>
              <div>
                <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                  <thead>
                  <tr>
                    <th style="text-align: center; ">Worker Name</th>
                    <th style="text-align: center; ">Hashrate</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(worker, index) in bscWorkerData" :key="index">
                    <td style="text-align: center; vertical-align: middle;">
                      {{ worker.workerName ? worker.workerName : 'unknown' }}
                    </td>
                    <td style="text-align: center; vertical-align: middle;">{{
                        calculateHashrate(worker.difficultySum)
                      }} GH/s
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </div>
          <div>
          <div class="profile-table second-table">
            <!-- <div class="whitespace-sm"></div> -->
            <table>
              <tr style="vertical-align:top" class="responsive-table">
                <td style="width:7.5%;">
                  <img src="../assets/images/etica_logo_100x100.png" class="bnbtc-logo" alt/>
                </td>
                <td style="width:42.5%;">
                  <div class="text-lg font-bold">ETI</div>
                  <div>Hashrate Average: {{ minerData.eticaAvgHashrate }} KH/s</div>
                  <div v-if="(rewardPeriodsEth.etica)">
                    <div>Tokens Earned Last 24h: {{ rawAmountToFormatted(etica24HourAverage, 18) }}</div>
                  </div>
                  <div>Tokens Earned: {{ ethTokenBalanceFormatted() }}</div>
                  <div>Tokens Paid: {{ ethTokensAwardedFormatted() }}</div>
                  <div>Tokens Donated: {{ ethTokensDonatedFormatted() }}</div>
                  <div>Tokens Balance: {{ ethTotalTokenBalanceFormatted() }}</div>
                </td>
                <td style="width:50%;">
                  <div class="text-lg font-bold">Share Credits</div>
                  <div>Share Credits: {{ minerData.eticaShareCredits || 0 }}</div>
                  <!-- <div v-if="minerData.poolRedirectDestination">
                    <div>0xbtc Pool Status Active: {{ remotePoolStatusForMiner.isActive }}</div>
                  </div> -->
                  <div>
                    Payout Threshold:
                    {{
                      minerData.eticaPayoutThreshold > 0
                          ? rawAmountToFormatted(minerData.eticaPayoutThreshold, 18)
                          : "Pool Default"
                    }}
                    <button v-show="ethAddress.toLowerCase() === publicAddress"
                        @click="openEticaPayoutModal"
                    >
                      <i class="far fa-edit"></i>
                    </button>
                  </div>
                  <ModalComponent v-if="showModal" :address="publicAddress" @close="showModal = false"></ModalComponent>
                  <SwitchPercentageModalComponent v-if="showSwitchPercentageModal" :address="publicAddress"
                                                  @close="showSwitchPercentageModal = false"></SwitchPercentageModalComponent>
                  <RequestPayout v-if="showRequestModal" :address="publicAddress"
                                 :tokenbalance="ethTotalTokenBalanceFormatted()" @close="showRequestModal = false"/>
                  <PriorityModalComponent v-if="showPriorityModal" :address="publicAddress"
                                          @close="showPriorityModal = false"></PriorityModalComponent>
                  <PayoutModalComponent v-if="showPayoutModal" :address="publicAddress"
                                        @close="showPayoutModal = false"></PayoutModalComponent>
                  <EticaPayoutThresholdModal v-if="showEticaPayoutModal" :address="publicAddress" :currentThreshold="minerData.eticaPayoutThreshold" @close="showEticaPayoutModal = false"  />

                </td>
              </tr>
            </table>

            <div v-if="eticaWorkerData.length > 0">
              <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
              <div style="text-align: center;">Total Number of Workers: {{ eticaWorkerData.length }}</div>
              <div>
                <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                  <thead>
                  <tr>
                    <th style="text-align: center; ">Worker Name</th>
                    <th style="text-align: center; ">Hashrate</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(worker, index) in eticaWorkerData" :key="index">
                    <td style="text-align: center; vertical-align: middle;">
                      {{ worker.workerName ? worker.workerName : 'unknown' }}
                    </td>
                    <td style="text-align: center; vertical-align: middle;">{{
                        calculateHashrateRandomX(worker.difficultySum)
                      }} KH/s
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          <div
              v-if="(minerData.springShareCredits || minerData.springAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7')" class="profile-table second-table">
              <!-- <div class="whitespace-sm"></div> -->
              <table>
                <tr style="vertical-align:top" class="responsive-table">
                  <td style="width:7.5%;">
                    <img src="../assets/images/spring_100x100.png" class="bnbtc-logo" alt/>
                  </td>
                  <td style="width:42.5%;">
                    <div class="text-lg font-bold">Spring</div>
                    <div>Hashrate Average: {{ hashrateToMH(minerData.springAvgHashrate) }} GH/s</div>
                    <div>Tokens Earned: {{ rawAmountToFormatted(minerData.springAlltimeTokenBalance, 18) }}</div>
                    <div>Tokens Paid: {{ rawAmountToFormatted(minerData.springTokensAwarded, 18) }}</div>
                    <div>Tokens Donated: {{ rawAmountToFormatted(minerData.springTokensDonated, 18) }}</div>
                    <div>Tokens Balance:
                      {{
                        rawAmountToFormatted((minerData.springAlltimeTokenBalance - (minerData.springTokensAwarded || 0)), 18)
                      }}
                    </div>
                  </td>

                  <td style="width:50%;">
                    <div class="text-lg font-bold">ETH Balance and Share Credits</div>
                    <div>Balance: {{ minerData.ethNativeToken.toFixed(18) }} ETH</div>
                    <div
                        v-if="minerData.ethNativeTokenCredit > 0"
                    >Credit: {{ minerData.ethNativeTokenCredit }} ETH
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= springShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >WARNING - Insufficient ETH Balance
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= springShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >will result in ineligibility for
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= springShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >reward until NEXT Payout Interval
                    </div>
                    <div>Share Credits: {{ minerData.springShareCredits }}</div>
                    <div v-if="springShareCreditEthEstimate.estimatedReward">
                      Estimated Reward:
                      {{ springShareCreditEthEstimate.estimatedReward }}
                    </div>
                    <div v-if="springShareCreditEthEstimate.shareCosts">
                      Estimated ETH Fee: {{
                        springShareCreditEthEstimate.shareCosts
                      }}
                    </div>
                    <div
                        v-if="springShareCreditEthEstimate.nextRoundUnixTime"
                    >Reward Period End: {{ springNextRoundEndLocale }}
                    </div>
                  </td>
                </tr>
              </table>

              <div v-if="springWorkerData.length > 0">
                <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
                <div style="text-align: center;">Total Number of Workers: {{ springWorkerData.length }}</div>
                <div>
                  <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                    <thead>
                    <tr>
                      <th style="text-align: center; ">Worker Name</th>
                      <th style="text-align: center; ">Hashrate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(worker, index) in springWorkerData" :key="index">
                      <td style="text-align: center; vertical-align: middle;">
                        {{ worker.workerName ? worker.workerName : 'unknown' }}
                      </td>
                      <td style="text-align: center; vertical-align: middle;">{{
                          calculateHashrate(worker.difficultySum)
                        }} GH/s
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          <div
              v-if="(minerData.summerShareCredits || minerData.summerAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7')">
            <div class="profile-table second-table">
              <!-- <div class="whitespace-sm"></div> -->
              <table>
                <tr style="vertical-align:top" class="responsive-table">
                  <td style="width:7.5%;">
                    <img src="../assets/images/summer_100x100.png" class="bnbtc-logo" alt/>
                  </td>
                  <td style="width:42.5%;">
                    <div class="text-lg font-bold">Summer</div>
                    <div>Hashrate Average: {{ hashrateToMH(minerData.summerAvgHashrate) }} GH/s</div>
                    <div>Tokens Earned: {{ rawAmountToFormatted(minerData.summerAlltimeTokenBalance, 18) }}</div>
                    <div>Tokens Paid: {{ rawAmountToFormatted(minerData.summerTokensAwarded, 18) }}</div>
                    <div>Tokens Donated: {{ rawAmountToFormatted(minerData.summerTokensDonated, 18) }}</div>
                    <div>Tokens Balance:
                      {{
                        rawAmountToFormatted((minerData.summerAlltimeTokenBalance - (minerData.summerTokensAwarded || 0)), 18)
                      }}
                    </div>
                  </td>

                  <td style="width:50%;">
                    <div class="text-lg font-bold">ETH Balance and Share Credits</div>
                    <div>Balance: {{ minerData.ethNativeToken.toFixed(18) }} ETH</div>
                    <div
                        v-if="minerData.ethNativeTokenCredit > 0"
                    >Credit: {{ minerData.ethNativeTokenCredit }} ETH
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= summerShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >WARNING - Insufficient ETH Balance
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= summerShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >will result in ineligibility for
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= summerShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >reward until NEXT Payout Interval
                    </div>
                    <div>Share Credits: {{ minerData.summerShareCredits }}</div>
                    <div v-if="summerShareCreditEthEstimate.estimatedReward">
                      Estimated Reward:
                      {{ summerShareCreditEthEstimate.estimatedReward }}
                    </div>
                    <div v-if="summerShareCreditEthEstimate.shareCosts">
                      Estimated ETH Fee: {{
                        summerShareCreditEthEstimate.shareCosts
                      }}
                    </div>
                    <div
                        v-if="summerShareCreditEthEstimate.nextRoundUnixTime"
                    >Reward Period End: {{ summerNextRoundEndLocale }}
                    </div>
                  </td>
                </tr>
              </table>

              <div v-if="summerWorkerData.length > 0">
                <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
                <div style="text-align: center;">Total Number of Workers: {{ summerWorkerData.length }}</div>
                <div>
                  <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                    <thead>
                    <tr>
                      <th style="text-align: center; ">Worker Name</th>
                      <th style="text-align: center; ">Hashrate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(worker, index) in summerWorkerData" :key="index">
                      <td style="text-align: center; vertical-align: middle;">
                        {{ worker.workerName ? worker.workerName : 'unknown' }}
                      </td>
                      <td style="text-align: center; vertical-align: middle;">{{
                          calculateHashrate(worker.difficultySum)
                        }} GH/s
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div
              v-if="(minerData.autumnShareCredits || minerData.autumnAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7')">
            <div class="profile-table second-table">
              <!-- <div class="whitespace-sm"></div> -->
              <table>
                <tr style="vertical-align:top" class="responsive-table">
                  <td style="width:7.5%;">
                    <img src="../assets/images/autumn_100x100.png" class="bnbtc-logo" alt/>
                  </td>
                  <td style="width:42.5%;">
                    <div class="text-lg font-bold">Autumn</div>
                    <div>Hashrate Average: {{ hashrateToMH(minerData.autumnAvgHashrate) }} GH/s</div>
                    <div>Tokens Earned: {{ rawAmountToFormatted(minerData.autumnAlltimeTokenBalance, 18) }}</div>
                    <div>Tokens Paid: {{ rawAmountToFormatted(minerData.autumnTokensAwarded, 18) }}</div>
                    <div>Tokens Donated: {{ rawAmountToFormatted(minerData.autumnTokensDonated, 18) }}</div>
                    <div>Tokens Balance:
                      {{
                        rawAmountToFormatted((minerData.autumnAlltimeTokenBalance - (minerData.autumnTokensAwarded || 0)), 18)
                      }}
                    </div>
                  </td>

                  <td style="width:50%;">
                    <div class="text-lg font-bold">ETH Balance and Share Credits</div>
                    <div>Balance: {{ minerData.ethNativeToken.toFixed(18) }} ETH</div>
                    <div
                        v-if="minerData.ethNativeTokenCredit > 0"
                    >Credit: {{ minerData.ethNativeTokenCredit }} ETH
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= autumnShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >WARNING - Insufficient ETH Balance
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= autumnShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >will result in ineligibility for
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= autumnShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >reward until NEXT Payout Interval
                    </div>
                    <div>Share Credits: {{ minerData.autumnShareCredits }}</div>
                    <div v-if="autumnShareCreditEthEstimate.estimatedReward">
                      Estimated Reward:
                      {{ autumnShareCreditEthEstimate.estimatedReward }}
                    </div>
                    <div v-if="autumnShareCreditEthEstimate.shareCosts">
                      Estimated ETH Fee: {{
                        autumnShareCreditEthEstimate.shareCosts
                      }}
                    </div>
                    <div
                        v-if="autumnShareCreditEthEstimate.nextRoundUnixTime"
                    >Reward Period End: {{ autumnNextRoundEndLocale }}
                    </div>
                  </td>

                </tr>
              </table>

              <div v-if="autumnWorkerData.length > 0">
                <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
                <div style="text-align: center;">Total Number of Workers: {{ autumnWorkerData.length }}</div>
                <div>
                  <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                    <thead>
                    <tr>
                      <th style="text-align: center; ">Worker Name</th>
                      <th style="text-align: center; ">Hashrate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(worker, index) in autumnWorkerData" :key="index">
                      <td style="text-align: center; vertical-align: middle;">
                        {{ worker.workerName ? worker.workerName : 'unknown' }}
                      </td>
                      <td style="text-align: center; vertical-align: middle;">{{
                          calculateHashrate(worker.difficultySum)
                        }} GH/s
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div
              v-if="(minerData.winterShareCredits || minerData.winterAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7')">
            <div class="profile-table second-table">
              <!-- <div class="whitespace-sm"></div> -->
              <table>
                <tr style="vertical-align:top" class="responsive-table">
                  <td style="width:7.5%;">
                    <img src="../assets/images/winter_100x100.png" class="bnbtc-logo" alt/>
                  </td>
                  <td style="width:42.5%;">
                    <div class="text-lg font-bold">Winter</div>
                    <div>Hashrate Average: {{ hashrateToMH(minerData.winterAvgHashrate) }} GH/s</div>
                    <div>Tokens Earned: {{ rawAmountToFormatted(minerData.winterAlltimeTokenBalance, 18) }}</div>
                    <div>Tokens Paid: {{ rawAmountToFormatted(minerData.winterTokensAwarded, 18) }}</div>
                    <div>Tokens Donated: {{ rawAmountToFormatted(minerData.winterTokensDonated, 18) }}</div>
                    <div>Tokens Balance:
                      {{
                        rawAmountToFormatted((minerData.winterAlltimeTokenBalance - (minerData.winterTokensAwarded || 0)), 18)
                      }}
                    </div>
                  </td>

                  <td style="width:50%;">
                    <div class="text-lg font-bold">ETH Balance and Share Credits</div>
                    <div>Balance: {{ minerData.ethNativeToken.toFixed(18) }} ETH</div>
                    <div
                        v-if="minerData.ethNativeTokenCredit > 0"
                    >Credit: {{ minerData.ethNativeTokenCredit }} ETH
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= winterShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >WARNING - Insufficient ETH Balance
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= winterShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >will result in ineligibility for
                    </div>
                    <div
                        v-if="(minerData.ethNativeToken+minerData.ethNativeTokenCredit) <= winterShareCreditEthEstimate.shareCosts "
                        style="color: #d53d50"
                    >reward until NEXT Payout Interval
                    </div>
                    <div>Share Credits: {{ minerData.winterShareCredits }}</div>
                    <div v-if="winterShareCreditEthEstimate.estimatedReward">
                      Estimated Reward:
                      {{ winterShareCreditEthEstimate.estimatedReward }}
                    </div>
                    <div v-if="winterShareCreditEthEstimate.shareCosts">
                      Estimated ETH Fee: {{
                        winterShareCreditEthEstimate.shareCosts
                      }}
                    </div>
                    <div
                        v-if="winterShareCreditEthEstimate.nextRoundUnixTime"
                    >Reward Period End: {{ winterNextRoundEndLocale }}
                    </div>
                  </td>
                </tr>
              </table>

              <div v-if="winterWorkerData.length > 0">
                <div class="text-lg font-bold" style="text-align: center;">Worker Data</div>
                <div style="text-align: center;">Total Number of Workers: {{ winterWorkerData.length }}</div>
                <div>
                  <table class="table w-full" style="margin-left: auto; margin-right: auto;">
                    <thead>
                    <tr>
                      <th style="text-align: center; ">Worker Name</th>
                      <th style="text-align: center; ">Hashrate</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(worker, index) in winterWorkerData" :key="index">
                      <td style="text-align: center; vertical-align: middle;">
                        {{ worker.workerName ? worker.workerName : 'unknown' }}
                      </td>
                      <td style="text-align: center; vertical-align: middle;">{{
                          calculateHashrate(worker.difficultySum)
                        }} GH/s
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div class="whitespace-md"></div>
          <div
              v-show="(minerData.springAlltimeTokenBalance || minerData.summerAlltimeTokenBalance || minerData.autumnAlltimeTokenBalance || minerData.winterAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7')" class="nav-container">
            <HorizontalNav
                class="mb-8"
                v-bind:activeSection="activeSection"
                v-bind:buttonClickedCallback="onHorizontalNavClicked"
                v-bind:buttonNamesArray="['BSC Transactions', 'ETI Transactions',  'Spring Transactions', 'Summer Transactions', 'Autumn Transactions', 'Winter Transactions']"
            />
          </div>
          <div
              v-show="!((minerData.springAlltimeTokenBalance || minerData.summerAlltimeTokenBalance || minerData.autumnAlltimeTokenBalance || minerData.winterAlltimeTokenBalance) && (ethAddress.toLowerCase() === publicAddress || ethAddress.toLowerCase() === '0xe444e367fa4b9da0cbdf98013a2871f053b95de7'))" class="nav-container">
            <HorizontalNav
                class="mb-8"
                v-bind:activeSection="activeSection"
                v-bind:buttonClickedCallback="onHorizontalNavClicked"
                v-bind:buttonNamesArray="['BSC Transactions', 'ETI Transactions' ]"
            />
          </div>

          <div v-if="activeSection ==='BSC Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div class="box background-secondary overflow-x-auto" style=" min-height:480px;">
              <div class="text-lg font-bold">Shares</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block #</td>
                  <td>Difficulty</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(share,i) in shares" :key="i">
                  <td class="px-1">{{ share.block }}</td>

                  <td class="px-1">{{ share.difficulty }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div class="box background-secondary overflow-x-auto" style="  min-height:480px;">
              <div class="text-lg font-bold">Payouts</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block #</td>
                  <td>Amount</td>
                  <td>BatchedPaymentUUID</td>
                  <td>txHash</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in payment_tx.bsc" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">{{ rawAmountToFormatted(tx.amountToPay, 8) }}</td>
                  <td class="px-1">{{ tx.batchedPaymentUuid }}</td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://bscscan.com/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="activeSection ==='ETI Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style=" min-height:480px;"
                v-if="(rewardPeriodsEth.etica)"
            >
              <div class="text-lg font-bold">Reward Period Statistics</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Time</td>
                  <td>Share Credits</td>
                  <td>Token Awarded</td>
                  <td>Status</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(rewardPeriod,i) in limitedRewards" :key="i">
                  <td class="px-1">{{ dateFormatted(rewardPeriod.unixTime * 1000) }}</td>

                  <td class="px-1">{{ rewardPeriod.shareCredits }}</td>
                  <td class="px-1">{{ rawAmountToFormatted(rewardPeriod.tokenAwarded, 18) }}</td>
                  <td class="px-1">{{ rewardPeriod.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div class="box background-secondary overflow-x-auto" style="  min-height:480px;">
              <div class="text-lg font-bold">Payouts</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block #</td>
                  <td>Amount</td>
                  <td>BatchedPaymentUUID</td>
                  <td>txHash</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in payment_tx.etica" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">{{ rawAmountToFormatted(tx.amountToPay, 18) }}</td>
                  <td class="px-1">{{ tx.batchedPaymentUuid }}</td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://eticascan.org/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--            <div
                            class="box background-secondary overflow-x-auto"
                            style="  min-height:480px;"
                            v-if="(totalMinerPayments.etica.outgoing_payments)"
                        >
                          <div class="text-lg font-bold">Outgoing Payments</div>
                          <table class="table w-full">
                            <thead>
                            <tr>
                              <td>Block#</td>
                              <td>Amount</td>
                              <td>txHash</td>
                              <td>Status</td>
                              <td>ETH Costs / Reason</td>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(tx,i) in totalMinerPayments.etica.outgoing_payments" :key="i">
                              <td class="px-1">{{ tx.block }}</td>

                              <td class="px-1">
                                <div v-if="(tx.txData)">{{ (tx.txData.amountToPay / 100000000).toFixed(18) }}</div>
                              </td>
                              <td class="px-1">
                                <a
                                    v-if="tx.txHash"
                                    v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                                    target="_blank"
                                >{{ tx.txHash }}</a>
                              </td>
                              <td class="px-1">{{ tx.status }}</td>
                              <td class="px-1" v-if="tx.reason">{{ tx.reason }}</td>
                              <td class="px-1" v-if="tx.txGas">{{ tx.txGas }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>-->
            <div class="whitespace-md"></div>

            <!--            <div
                            class="box background-secondary overflow-x-auto"
                            style="  min-height:480px;"
                            v-if="(totalMinerPayments.etica.incoming_payments)"
                        >
                          <div class="text-lg font-bold">Incoming Payments</div>
                          <table class="table w-full">
                            <thead>
                            <tr>
                              <td>Timestamp</td>
                              <td>Amount in ETH</td>
                              <td>txHash</td>
                              <td>confirmed</td>
                            </tr>
                            </thead>

                            <tbody>
                            <tr v-for="(tx,i) in totalMinerPayments.etica.incoming_payments" :key="i">
                              <td class="px-1">{{ tx.createdAt }}</td>
                              <td class="px-1">{{ tx.amount }}</td>
                              <td class="px-1">
                                <a
                                    v-if="tx.txHash"
                                    v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                                    target="_blank"
                                >{{ tx.txHash }}</a>
                              </td>
                              <td class="px-1">{{ tx.confirmed }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>-->
          </div>

          <div v-if="activeSection ==='Spring Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style=" min-height:480px;"
                v-if="(rewardPeriodsEth.spring)"
            >
              <div class="text-lg font-bold">Reward Period Statistics</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Time</td>
                  <td>Share Credits</td>
                  <td>Token Awarded</td>
                  <td>ETH Fee</td>
                  <td>Effective Fee</td>
                  <td>Status</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(rewardPeriod,i) in rewardPeriodsEth.spring" :key="i">
                  <td class="px-1">{{ dateFormatted(rewardPeriod.unixTime * 1000) }}</td>

                  <td class="px-1">{{ rewardPeriod.shareCredits }}</td>
                  <td class="px-1">{{ rawAmountToFormatted(rewardPeriod.tokenAwarded, 18) }}</td>
                  <td class="px-1">{{ rewardPeriod.gasDeducted.toFixed(18) }}</td>
                  <td class="px-1">
                    <div v-if="rewardPeriod.effectiveFee">{{ (rewardPeriod.effectiveFee * 100).toFixed(2) }}%</div>
                  </td>
                  <td class="px-1">{{ rewardPeriod.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style="  min-height:480px;"
                v-if="(totalMinerPayments.spring.outgoing_payments)"
            >
              <div class="text-lg font-bold">Outgoing Payments</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block#</td>
                  <td>Amount</td>
                  <td>txHash</td>
                  <td>Status</td>
                  <td>ETH Costs / Reason</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in totalMinerPayments.spring.outgoing_payments" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">
                    <div v-if="(tx.txData)">{{ (tx.txData.amountToPay / 1000000000000000000).toFixed(18) }}</div>
                  </td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                  <td class="px-1">{{ tx.status }}</td>
                  <td class="px-1" v-if="tx.reason">{{ tx.reason }}</td>
                  <td class="px-1" v-if="tx.txGas">{{ tx.txGas }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="whitespace-md"></div>
          </div>

          <div v-if="activeSection ==='Summer Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style=" min-height:480px;"
                v-if="(rewardPeriodsEth.summer)"
            >
              <div class="text-lg font-bold">Reward Period Statistics</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Time</td>
                  <td>Share Credits</td>
                  <td>Token Awarded</td>
                  <td>ETH Fee</td>
                  <td>Effective Fee</td>
                  <td>Status</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(rewardPeriod,i) in rewardPeriodsEth.summer" :key="i">
                  <td class="px-1">{{ dateFormatted(rewardPeriod.unixTime * 1000) }}</td>

                  <td class="px-1">{{ rewardPeriod.shareCredits }}</td>
                  <td class="px-1">{{ rawAmountToFormatted(rewardPeriod.tokenAwarded, 18) }}</td>
                  <td class="px-1">{{ rewardPeriod.gasDeducted.toFixed(18) }}</td>
                  <td class="px-1">
                    <div v-if="rewardPeriod.effectiveFee">{{ (rewardPeriod.effectiveFee * 100).toFixed(2) }}%</div>
                  </td>
                  <td class="px-1">{{ rewardPeriod.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style="  min-height:480px;"
                v-if="(totalMinerPayments.summer.outgoing_payments)"
            >
              <div class="text-lg font-bold">Outgoing Payments</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block#</td>
                  <td>Amount</td>
                  <td>txHash</td>
                  <td>Status</td>
                  <td>ETH Costs / Reason</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in totalMinerPayments.summer.outgoing_payments" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">
                    <div v-if="(tx.txData)">{{ (tx.txData.amountToPay / 1000000000000000000).toFixed(18) }}</div>
                  </td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                  <td class="px-1">{{ tx.status }}</td>
                  <td class="px-1" v-if="tx.reason">{{ tx.reason }}</td>
                  <td class="px-1" v-if="tx.txGas">{{ tx.txGas }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="whitespace-md"></div>
          </div>

          <div v-if="activeSection ==='Autumn Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style=" min-height:480px;"
                v-if="(rewardPeriodsEth.autumn)"
            >
              <div class="text-lg font-bold">Reward Period Statistics</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Time</td>
                  <td>Share Credits</td>
                  <td>Token Awarded</td>
                  <td>ETH Fee</td>
                  <td>Effective Fee</td>
                  <td>Status</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(rewardPeriod,i) in rewardPeriodsEth.autumn" :key="i">
                  <td class="px-1">{{ dateFormatted(rewardPeriod.unixTime * 1000) }}</td>

                  <td class="px-1">{{ rewardPeriod.shareCredits }}</td>
                  <td class="px-1">{{ rawAmountToFormatted(rewardPeriod.tokenAwarded, 18) }}</td>
                  <td class="px-1">{{ rewardPeriod.gasDeducted.toFixed(18) }}</td>
                  <td class="px-1">
                    <div v-if="rewardPeriod.effectiveFee">{{ (rewardPeriod.effectiveFee * 100).toFixed(2) }}%</div>
                  </td>
                  <td class="px-1">{{ rewardPeriod.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style="  min-height:480px;"
                v-if="(totalMinerPayments.autumn.outgoing_payments)"
            >
              <div class="text-lg font-bold">Outgoing Payments</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block#</td>
                  <td>Amount</td>
                  <td>txHash</td>
                  <td>Status</td>
                  <td>ETH Costs / Reason</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in totalMinerPayments.autumn.outgoing_payments" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">
                    <div v-if="(tx.txData)">{{ (tx.txData.amountToPay / 1000000000000000000).toFixed(18) }}</div>
                  </td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                  <td class="px-1">{{ tx.status }}</td>
                  <td class="px-1" v-if="tx.reason">{{ tx.reason }}</td>
                  <td class="px-1" v-if="tx.txGas">{{ tx.txGas }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="whitespace-md"></div>
          </div>

          <div v-if="activeSection ==='Winter Transactions'" class="mb-4">
            <div class="whitespace-sm"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style=" min-height:480px;"
                v-if="(rewardPeriodsEth.winter)"
            >
              <div class="text-lg font-bold">Reward Period Statistics</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Time</td>
                  <td>Share Credits</td>
                  <td>Token Awarded</td>
                  <td>ETH Fee</td>
                  <td>Effective Fee</td>
                  <td>Status</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(rewardPeriod,i) in rewardPeriodsEth.winter" :key="i">
                  <td class="px-1">{{ dateFormatted(rewardPeriod.unixTime * 1000) }}</td>

                  <td class="px-1">{{ rewardPeriod.shareCredits }}</td>
                  <td class="px-1">{{ rawAmountToFormatted(rewardPeriod.tokenAwarded, 18) }}</td>
                  <td class="px-1">{{ rewardPeriod.gasDeducted.toFixed(18) }}</td>
                  <td class="px-1">
                    <div v-if="rewardPeriod.effectiveFee">{{ (rewardPeriod.effectiveFee * 100).toFixed(2) }}%</div>
                  </td>
                  <td class="px-1">{{ rewardPeriod.status }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="whitespace-md"></div>

            <div
                class="box background-secondary overflow-x-auto"
                style="  min-height:480px;"
                v-if="(totalMinerPayments.winter.outgoing_payments)"
            >
              <div class="text-lg font-bold">Outgoing Payments</div>
              <table class="table w-full">
                <thead>
                <tr>
                  <td>Block#</td>
                  <td>Amount</td>
                  <td>txHash</td>
                  <td>Status</td>
                  <td>ETH Costs / Reason</td>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(tx,i) in totalMinerPayments.winter.outgoing_payments" :key="i">
                  <td class="px-1">{{ tx.block }}</td>

                  <td class="px-1">
                    <div v-if="(tx.txData)">{{ (tx.txData.amountToPay / 1000000000000000000).toFixed(18) }}</div>
                  </td>
                  <td class="px-1">
                    <a
                        v-if="tx.txHash"
                        v-bind:href="'https://etherscan.io/tx/' + tx.txHash"
                        target="_blank"
                    >{{ tx.txHash }}</a>
                  </td>
                  <td class="px-1">{{ tx.status }}</td>
                  <td class="px-1" v-if="tx.reason">{{ tx.reason }}</td>
                  <td class="px-1" v-if="tx.txGas">{{ tx.txGas }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="whitespace-md"></div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  </div>
</template>


<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

import MathHelper from "../js/math-helper";

import SocketHelper from "../js/socket-helper";
import ModalComponent from "./Modal";
// import RemotePoolModalComponent from "./RemotePoolModal";
import RequestPayout from './RequestPayoutModal';
import PriorityModalComponent from "./PriorityModal";
import PayoutModalComponent from "./PayoutModal";
import SwitchPercentageModalComponent from "./SwitchPercentageModal";
import EticaPayoutThresholdModal from './EticaPayoutThresholdModal'; // Adjust the path as needed

import HorizontalNav from "./components/HorizontalNav.vue";
import Moralis from "@/views/plugins/moralis.js";
import web3utils from "web3-utils";

export default {
  name: "Profile",
  props: [],
  components: {
    Navbar,
    Footer,
    ModalComponent,
    PriorityModalComponent,
    PayoutModalComponent,
    SwitchPercentageModalComponent,
    EticaPayoutThresholdModal,
    HorizontalNav,
    RequestPayout
  },
  data() {
    return {
      priorityName: "BNBTC",
      publicAddress: null,
      hashrateCalculationPeriod: 60 * 60,
      bscWorkerData: [],
      eticaWorkerData: [],
      springWorkerData: [],
      summerWorkerData: [],
      autumnWorkerData: [],
      winterWorkerData: [],
      nextRoundEndLocale: "",
      springNextRoundEndLocale: "",
      summerNextRoundEndLocale: "",
      autumnNextRoundEndLocale: "",
      winterNextRoundEndLocale: "",
      minerData: {},
      remotePoolStatusForMiner: {displayName: null},
      remotePoolStatus: [],
      shareCreditEthEstimate: {},
      springShareCreditEthEstimate: {},
      summerShareCreditEthEstimate: {},
      autumnShareCreditEthEstimate: {},
      winterShareCreditEthEstimate: {},
      shares: [],
      rewardPeriodsEth: [],
      payment_tx: [],
      totalMinerPayments: [],
      showModal: false,
      showEticaPayoutModal: false,
      payoutThreshold: null,
      showPayoutModal: false,
      showPriorityModal: false,
      showRequestModal: false,
      showSwitchPercentageModal: false,
      switchPercentage: null,
      activeSection: "BSC Transactions",
      bsctokenbalance: null,
      connected: false,
      ethAddress: ''
    };
  },
  mounted() {

    this.$root.$on("changedCommunityfee", value => {
      this.minerData.communityFee = value;
    });
    if (Moralis.User.current()) {
      this.connected = true
      this.ethAddress = Moralis.User.current().attributes.ethAddress
    }
    this.$root.$on('connect', (ethAddress) => {
      this.ethAddress = ethAddress;
    });
    this.$root.$on('disconnect', (ethAddress) => {
      this.ethAddress = ethAddress;
    });
  },

  created() {
    this.publicAddress = this.$route.params.publicAddress;


    this.socketHelper = new SocketHelper();

    setInterval(this.pollSockets.bind(this), 5000);

    this.socketsListener = this.socketHelper.initSocket();

    if (this.currentThreshold !== undefined) {
      this.payoutThreshold = this.currentThreshold;
    }

    this.socketsListener.on("minerData", data => {
      if (data.priority === 1 || data.priority === null) {
        this.priorityName = "BNBTC";
      }
      if (data.priority === 2) {
        this.priorityName = "ETI";
      }
      if (data.switchPercentage) {
        this.switchPercentage = data.switchPercentage + " %"
      } else {
        this.switchPercentage = null
      }
      //console.log('got minerData', data)
      this.minerData = data;

      // Check if bscAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.bscAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "bsc"
        });
      } else {
        this.bscWorkerData = [];
      }

      // Check if eticaAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.eticaAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "etica"
        });
      } else {
        this.eticaWorkerData = [];
      }

      // Check if springAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.springAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "spring"
        });
      } else {
        this.springWorkerData = [];
      }

      // Check if summerAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.summerAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "summer"
        });
      } else {
        this.summerWorkerData = [];
      }

      // Check if autumnAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.autumnAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "autumn"
        });
      } else {
        this.autumnWorkerData = [];
      }

      // Check if winterAvgHashrate is greater than 0 before emitting getMinerWorkers
      if (this.minerData.winterAvgHashrate > 0) {
        this.socketHelper.emitEvent("getMinerWorkers", {
          ethMinerAddress: this.publicAddress,
          chain: "winter"
        });
      } else {
        this.winterWorkerData = [];
      }


    });
    /*    this.socketsListener.on("remotePoolStatusForMiner", data => {
          //console.log('got getRemotePoolStatus data',data)
          if (data) {
            this.remotePoolStatusForMiner = data;
          } else {
            this.remotePoolStatusForMiner = {displayName: null};
          }
        });
        this.socketsListener.on("remotePoolStatus", data => {
          //console.log('got shares',data)
          window.remotePoolStatus = data;
          //console.log(data)
        });*/

    this.socketsListener.on("minerShares", data => {
      //console.log('got shares',data)
      this.shares = data.bsc; // only BSC data is populated
    });

    this.socketsListener.on("minerWorkersbsc", data => {
      //console.log('got bsc workers',data)
      this.bscWorkerData = data
    });

    this.socketsListener.on("minerWorkersetica", data => {
      console.log('got eti workers',data)
      this.eticaWorkerData = data
    });
    this.socketsListener.on("minerWorkersspring", data => {
      //console.log('got spring workers',data)
      this.springWorkerData = data
    });
    this.socketsListener.on("minerWorkerssummer", data => {
      //console.log('got summer workers',data)
      this.summerWorkerData = data
    });
    this.socketsListener.on("minerWorkersautumn", data => {
      //console.log('got autumn workers',data)
      this.autumnWorkerData = data
    });
    this.socketsListener.on("minerWorkerswinter", data => {
      //console.log('got winter workers',data)
      this.winterWorkerData = data
    });

    this.socketsListener.on("minerPayments", data => {
      //console.log('got payments',data)
      this.payment_tx = data; // only BSC data is populated
    });

    this.socketsListener.on("totalMinerPayments", data => {
      //console.log('totalMinerPayments',data)
      this.totalMinerPayments = data;
    });
    this.socketsListener.on("minerRewardStatistics", data => {
      //console.log('minerRewardStatistics',data)
      this.rewardPeriodsEth = data;
    });

    this.socketsListener.on("shareCreditEthEstimate", data => {
      //console.log('got shareCreditEthEstimate',data)
      this.nextRoundEndLocale = new Date(
          data.nextRoundUnixTime * 1000
      ).toLocaleTimeString();
      this.shareCreditEthEstimate = data;
    });
    this.socketsListener.on("seasonShareCreditEthEstimate", data => {
      //console.log('got seasonShareCreditEthEstimate',data)
      this.springNextRoundEndLocale = new Date(
          data.spring.nextRoundUnixTime * 1000
      ).toLocaleTimeString();
      this.summerNextRoundEndLocale = new Date(
          data.summer.nextRoundUnixTime * 1000
      ).toLocaleTimeString();
      this.autumnNextRoundEndLocale = new Date(
          data.autumn.nextRoundUnixTime * 1000
      ).toLocaleTimeString();
      this.winterNextRoundEndLocale = new Date(
          data.winter.nextRoundUnixTime * 1000
      ).toLocaleTimeString();
      this.springShareCreditEthEstimate = data.spring;
      this.summerShareCreditEthEstimate = data.summer;
      this.autumnShareCreditEthEstimate = data.autumn;
      this.winterShareCreditEthEstimate = data.winter;
    });

    this.pollSockets();
  },
  computed: {
    etica24HourAverage() {
      const data = this.rewardPeriodsEth.etica;
      if (!data || data.length === 0) return 0; // Check if data exists and is not empty

      const now = Math.floor(Date.now() / 1000); // Current time in seconds
      const oneDayAgo = now - (24 * 60 * 60); // 24 hours ago in seconds

      const filteredData = data.filter(item => item.unixTime > oneDayAgo);
      if (filteredData.length === 0) return 0; // Check if filtered data is not empty

      return filteredData.reduce((acc, item) => acc + parseInt(item.tokenAwarded), 0);
    },
    limitedRewards() {
      // Determine the key in rewardPeriodsEth based on the activeSection
      let key = ""; // Default key
      switch (this.activeSection) {
        case 'ETI Transactions':
          key = "etica";
          break;
        case 'Spring Transactions':
          key = "spring";
          break;
        case 'Summer Transactions':
          key = "summer";
          break;
        case 'Autumn Transactions':
          key = "autumn";
          break;
        case 'Winter Transactions':
          key = "winter";
          break;
          // Add more cases as necessary for other tokens/sections
      }

      // Check if the data exists for the current key and is an array
      if (key && Array.isArray(this.rewardPeriodsEth[key])) {
        // Return the first 50 elements of the corresponding array
        return this.rewardPeriodsEth[key].slice(0, 50);
      }

      // Return an empty array if the data is not available
      return [];
    }
  },
  methods: {
    pollSockets() {
      this.socketHelper.emitEvent("getMinerData", {
        ethMinerAddress: this.publicAddress
      });
      /*      this.socketHelper.emitEvent("getRemotePoolStatusForMiner", {
              ethMinerAddress: this.publicAddress
            });*/
      //this.socketHelper.emitEvent("getRemotePoolStatus", {});
      this.socketHelper.emitEvent("getMinerShares", {
        ethMinerAddress: this.publicAddress
      });
      this.socketHelper.emitEvent("getMinerPayments", {
        ethMinerAddress: this.publicAddress
      });
      this.socketHelper.emitEvent("getTotalMinerPayments", {
        ethMinerAddress: this.publicAddress
      });
      this.socketHelper.emitEvent("getMinerRewardStatistics", {
        ethMinerAddress: this.publicAddress
      });
      this.socketHelper.emitEvent("getSeasonShareCreditEthEstimate", {
        ethMinerAddress: this.publicAddress,
      });
    },
    onHorizontalNavClicked(item) {
      this.activeSection = item;
    },
    rawAmountToFormatted(token, radix) {
      if (isNaN(token)) {
        return MathHelper.rawAmountToFormatted(0, radix);
      }
      return MathHelper.rawAmountToFormatted(token, radix);
    },

    ethTotalTokenBalanceFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.eticaAlltimeTokenBalance - this.minerData.eticaTokensAwarded,
          18
      );
    },

    bscTotalTokenBalanceFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.bscAlltimeTokenBalance - this.minerData.bscTokensAwarded,
          8
      );
    },

    bscTokenBalanceFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.bscAlltimeTokenBalance,
          8
      );
    },

    ethTokenBalanceFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.eticaAlltimeTokenBalance,
          18
      );
    },

    dateFormatted(date) {
      let dateFormatted = new Date(date);
      return dateFormatted.toLocaleString();
    },

    bscTokensAwardedFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.bscTokensAwarded,
          8
      );
    },
    ethTokensAwardedFormatted() {
      return MathHelper.rawAmountToFormatted(
          this.minerData.eticaTokensAwarded,
          18
      );
    },
    bscTokensDonatedFormatted() {
      if (isNaN(this.minerData.bscTokensDonated)) {
        this.minerData.bscTokensDonated = 0;
      }
      return MathHelper.rawAmountToFormatted(
          this.minerData.bscTokensDonated,
          8
      );
    },
    ethTokensDonatedFormatted() {
      if (isNaN(this.minerData.eticaTokensDonated)) {
        this.minerData.eticaTokensDonated = 0;
      }
      return MathHelper.rawAmountToFormatted(
          this.minerData.eticaTokensDonated,
          18
      );
    },
    communityFeeFormatted() {
      if (isNaN(this.minerData.communityFee)) {
        this.minerData.communityFee = 0;
      }
      return this.minerData.communityFee;
    },
    hashrateToMH(hashrate) {
      return MathHelper.rawAmountToFormatted(hashrate, 6);
    },
    OpenModal() {
      this.showModal = true;
    },
    OpenPriorityModal() {
      this.showPriorityModal = this.showPriorityModal !== true;
    },
    openEticaPayoutModal() {
      this.showEticaPayoutModal = true;
    },
    OpenPayoutModal() {
      this.showPayoutModal = this.showPayoutModal !== true;
    },
    OpenSwapModal() {
      this.showRequestModal = true;
      this.bsctokenbalance = this.bscTotalTokenBalanceFormatted();

    },
    calculateHashrate(difficulty) {
      let avgHashrate = web3utils.toBN(difficulty).mul(web3utils.toBN(2).pow(web3utils.toBN(32))).div(web3utils.toBN(this.hashrateCalculationPeriod))
      avgHashrate = parseInt((avgHashrate.toNumber() / 1000).toFixed(6));
      return this.hashrateToMH(avgHashrate)
    },

    calculateHashrateRandomX(difficulty) {
      let avgHashrate = web3utils.toBN(difficulty).div(web3utils.toBN(this.hashrateCalculationPeriod))
      avgHashrate = parseFloat((avgHashrate.toNumber() / 1000).toFixed(4));
      return avgHashrate
    }
  }
};
</script>
<style>
.whitespace-sm {
  margin-top: 0;
}

.last-column {
  display: flex;
  flex-direction: column;
}

.bnbtc-logo {
  width: 45px;
  height: 45px;
}

.profile-table {
  border: 2px solid rgba(160, 174, 192);
  padding: 16px;
}


.second-table {
  position: relative;
  top: -2px;
  border-top: none; /* Remove bottom border */
}

.request {
  float: right;
  display: block;
}

.second-table table {
  width: 100%;
}

.not-visible {
  visibility: hidden;
}

@media screen and (max-width: 800px) {
  .responsive-table td {
    width: 100% !important; /* Forces each cell to take full width */
    display: block; /* Stacks cells vertically */
    box-sizing: border-box; /* Ensures padding is included in width */
  }

  .profile-table, .second-table {
    padding-left: 10px; /* Adjusts padding for mobile */
    padding-right: 0; /* Adjusts padding for mobile */
  }

  .text-lg {
    text-align: center;
  }

  .bnbtc-logo {
    margin: 0 auto 10px; /* Centers logo and adds space below */
    display: block;
  }

  .request {
    float: none; /* Removes float for mobile view */
  }

  .not-visible {
    display: none; /* Keeps certain elements hidden */
  }


  .section {
    padding: 10px;
  }

  .not-visible {
    display: none;
  }
}
</style>
